<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{
              operation != null ?
                (operation === 'add' ? 'Add Lead' : (operation === 'detail' ? 'Lead Detail'
                  : 'Edit Event')) : operationTitle
            }}
            <p><small>Manage Leads and Lead Status</small></p>
          </template>
          <template v-slot:toolbar>
            <b-input-group-append>
              <router-link to="/events/create">
                <b-button :title="`Add New`"
                          class="btn btn-primary font-weight-bolder"
                          v-b-tooltip.hover
                          v-if="globalPropertyId > 0 && $global.hasPermission('eventsstore')"
                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                </b-button>
              </router-link>
              <b-button
                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                v-b-toggle.sidebar-backdrop-events>
                <i class="fa fa-filter fa-sm"></i> Filter
              </b-button>
              <b-button
                @click="handleShowFollowup"
                :class="show_follow_up ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'">
                Today's Follow Ups ({{ pendingFollowUpCount }})
              </b-button>

              <b-button class="btn btn-light-primary font-weight-bolder ml-2" @click="downloadReport">
                <i class="fa fa-download fa-sm"></i> Lead Report
              </b-button>
            </b-input-group-append>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="events-table" v-if="$global.hasPermission('eventsview')">
                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select :options="[10, 50, 100]"
                                       class="datatable-select"
                                       size="sm"
                                       v-model="pagination.totalPerPage"></b-form-select>
                        entries</label></div>

                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                        <b-form-input class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      type="search"
                                      placeholder="Search by Name and Mobile"
                                      v-model="search"
                                      v-on:keyup.enter="handleSearch"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="dataSource"
                  :items-per-page="parseInt(pagination.totalPerPage)"
                  :loading="loading"
                  borderd
                  class="table table-head-custom table-vertical-center table-responsive"
                  hide-default-footer
                  ref="table"
                  responsive
                >
                  <template v-slot:item.id="{item}">
                    {{ item.id }}
                  </template>
                  <template v-slot:item.title="{item}">
                    <div v-b-tooltip.hover :title="item.description">{{ item.title }}</div>
                    <div>{{ item.owner ? item.owner.name : '' }}</div>
                  </template>
                  <template v-slot:item.halls="record">
                    <div class="mb-1" :key="index" v-for="(hall,index) in record.item.halls">
                      <b-tag>
                        {{ hall.name }}
                      </b-tag>
                    </div>
                  </template>
                  <template v-slot:item.leads="record">
                    <div>
                      {{
                        record.item.leads ? record.item.leads.title : ""
                      }}
                    </div>
                    <div>
                      {{ record.item.leads ? record.item.leads.mobile_number : "" }}
                    </div>
                  </template>
                  <template v-slot:item.event_type="{item}">
                    <div>{{ item.event_type }}</div>
                    <div>{{ item.no_of_guest }}</div>
                  </template>
                  <template v-slot:item.source_city="{item}">
                    <div>{{ item.leads.sources ? item.leads.sources.source_name : '' }}</div>
                    <div>{{ item.leads.city }}</div>
                  </template>
                  <template v-slot:item.created_at="{item}">
                    <div>{{ $global.dateFormat(item.created_at) }}</div>
                    <div>{{ item.users ? item.users.name : "" }}</div>
                  </template>
                  <template v-slot:item.event_from_date="record">
                    <div>{{ $global.dateFormat(record.item.event_from_date) }}</div>
                    <div>{{ $global.dateFormat(record.item.event_to_date) }}</div>
                  </template>
                  <template v-slot:item.status="{item}">
                    <div v-if="item.status"
                          :title="item.status === 'Lost' && item.drop_analytics ? item.drop_analytics : ''"
                          v-b-tooltip.hover
                          :class="`label font-weight-bolder label-xl label-inline ${getStatusTag(item.status)}`">
                            {{ item.status }}
                    </div>
                  </template>
                  <template v-slot:item.tags="{item}">
                    <div>
                      <b-tag class="m-1" v-for="(tag, index) in item.tags" :key="index">
                        {{tag}}
                      </b-tag>
                    </div>
                  </template>
                  <template v-slot:item.follow_up="record">
                    <div v-if="record.item.follow_up.length > 0">
                      <div
                        :title="record.item.follow_up.slice(-1)[0] ? record.item.follow_up.slice(-1)[0].comments : ''"
                        v-b-tooltip.hover>
                        {{ $global.dateFormat(record.item.follow_up.slice(-1)[0].date) }}<br/>
                        {{ record.item.follow_up.slice(-1)[0].time }}
                        ({{ record.item.follow_up.length }})
                      </div>
                    </div>
                  </template>

                  <template class="action-column" v-slot:item.action="record">
                    <b-dropdown no-caret size="sm" toggle-class="text-decoration-none"
                                variant="link">
                      <template #button-content>
                        <p class="btn btn-icon btn-light btn-hover-primary btn-sm pt-1 pb-1">
                         <span
                           class="svg-icon svg-icon-md svg-icon-primary m-0">
                          <!--begin::Svg Icon-->
                            <inline-svg
                              class="action-edit-button"
                              src="/media/svg/icons/General/Settings-1.svg"
                            ></inline-svg>
                           <!--end::Svg Icon-->
                        </span>
                        </p>
                      </template>
                      <b-dropdown-item
                        @click="editEvent(record.item.id)"
                        v-show="$global.hasPermission('eventsupdate')">
                          <span class="pt-1 pb-1" size="sm">
                                  <i class="fa fa-edit mr-2"></i> Edit
                              </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleFollowUpDetail(record.item.id)" href="#"
                                       v-if="$global.hasPermission('followupsview') && record.item.status !== 'Lost'">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-message mr-2"></i> Follow Up
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleCloseFollowup(record.item.id)" href="#"
                                       v-if="$global.hasPermission('followupsview') && record.item.followup_closed === false && record.item.follow_up && record.item.follow_up.length > 0">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-close mr-2"></i> Close followup
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleBrochureDetail(record.item.id)" href="#"
                                       v-show="$global.hasPermission('sendbrochuresstore')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-share mr-2"></i> Send Brochure
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleBounceEvent(record.item)"
                                       href="#"
                                       v-if="!record.item.bounce && !$global.hasPermission('companyprofilesview') && !record.item.is_booking">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-star mr-2"></i> Bounce Event
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="convertToQuotation(record.item.id)"
                        v-if="$global.hasPermission('quotationsstore') && !record.item.is_quotation && record.item.status !== 'Lost' && !record.item.is_booking">
                              <span class="pt-1 pb-1" size="sm">
                                      <i class="fa fa-file mr-2"></i> Create Quotation
                              </span>
                      </b-dropdown-item>
                      <!--                      <b-dropdown-item
                                              @click="convertToQuotation(record.item.id, true)"
                                              v-if="$global.hasPermission('quotationsstore') && !record.item.is_invoice && record.item.status !== 'Lost'">
                                                    <span class="pt-1 pb-1" size="sm">
                                                            <i class="fa fa-file mr-2"></i> Create Invoice
                                                    </span>
                                            </b-dropdown-item>-->
                      <b-dropdown-item
                        @click="convertToBooking(record.item.id)"
                        v-if="$global.hasPermission('quotationbookingsstore') && !record.item.is_booking && record.item.status !== 'Lost'">
                              <span class="pt-1 pb-1" size="sm">
                                      <i class="fa fa-file mr-2"></i> Create Booking
                              </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="$router.push(`/bookings/edit/${record.item.booking_id}`)"
                        v-if="$global.hasPermission('quotationbookingsview') && record.item.is_booking && record.item.status !== 'Lost'">
                              <span class="pt-1 pb-1" size="sm">
                                      <i class="fa fa-file mr-2"></i> Go to Booking
                              </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="convertToBooking(record.item.id, 5)"
                        v-if="$global.hasPermission('quotationbookingsstore') && !record.item.is_booking && record.item.status !== 'Lost'">
                              <span class="pt-1 pb-1" size="sm">
                                      <i class="fa fa-file mr-2"></i> Create Tentative Booking
                              </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleDeleteOperation(record.item.id)" href="#"
                                       v-show="$global.hasPermission('eventsdestroy') && !record.item.is_booking">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-trash mr-2"></i> Delete
                                                </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                      :per-page="pagination.totalPerPage"
                      :total-rows="pagination.total"
                      size="lg"
                      v-model="pagination.current"
                    ></b-pagination>

                  </div>
                </div><!-- /.pull-right -->
              </div>
              <div v-if="operation !== 'detail' && operation !== null">
                <event-form></event-form>
              </div>
            </v-card>
          </template>
        </KTCard>
        <template>
          <div>
            <b-sidebar
              :backdrop-variant="`transparent`"
              backdrop
              id="sidebar-backdrop-events"
              shadow
              title="Filters"
              right
            >
              <template #header="{ hide }">
                <b-col sm="10">
                  <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                </b-col>
                <b-col sm="2">
                  <button @click="hide();"
                          class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </button>
                </b-col>
              </template>
              <template #footer="{ hide }">
                <div
                  class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                  <b-button @click="hide();handleResetFilterClick()"
                            class="btn btn-light-primary font-weight-bolder ml-2">
                    <i class="fa fa-broom fa-sm"></i> Clear All
                  </b-button>
                </div>
              </template>
              <div class="px-10 py-4">
                <b-col sm="12">
                  <b-form-group
                    label="Status"
                    label-for="title">
                    <treeselect
                      :multiple="true"
                      :options="[{id:'Standard',label:'Standard'},{id:'Hot',label:'Hot'},{id:'Won',label:'Won'},{id:'Qualified',label:'Qualified'},{id:'Lost',label:'Lost'}]"
                      v-model="filters.status"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Tags"
                    label-for="tags">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.tags"
                      v-model="filters.tags"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event Start Date"
                    label-for="event_from_date">
                    <b-form-datepicker
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.event_from_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event End Date"
                    label-for="event_to_date">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled(filters.event_from_date)"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :disabled="!filters.event_from_date"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.event_to_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Created from"
                    label-for="created_from">
                    <b-form-datepicker
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.created_from"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Created to"
                    label-for="created_to">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled(filters.created_to)"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :disabled="!filters.created_from"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.created_to"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event Owner"
                    label-for="filter-lead-owner">
                    <treeselect
                      :options="dropdowns.subUsers"
                      class="mb-2"
                      id="filter-lead-owner"
                      v-model="filters.event_owner"></treeselect>
                  </b-form-group>
                </b-col><!--/b-col-->
                <b-col sm="12">
                  <b-form-group
                    label="Hall"
                    label-for="halls">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.halls"
                      v-model="filters.halls">
                    </treeselect>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event type"
                    label-for="event_type">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.eventType"
                      v-model="filters.event_type"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col><!--/b-col-->
                <b-col sm="12">
                  <b-form-group
                    label="Sources"
                    label-for="sources">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.sources"
                      v-model="filters.sources"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col><!--/b-col-->
              </div>
            </b-sidebar>
          </div>
        </template>
      </div>
    </div>
    <b-modal hide-footer id="follow-up" size="lg" title="Event Follow-Up"
             v-if="$global.hasPermission('followupsview')">
      <follow-up @update="getPendingFollowUpCount" v-bind:event="selectedEvent"></follow-up>
    </b-modal>
    <b-modal hide-footer id="send-brochure" size="lg" title="Send Brochure"
             v-if="$global.hasPermission('sendbrochuresview')">
      <send-brochure v-bind:event="selectedBrochureEvent"></send-brochure>
    </b-modal>
    <!--        <b-modal id="event-details" hide-footer size="xl" title="Event Details">-->
    <!--            <b-row v-if="eventDetail">-->
    <!--                <b-col sm="4" class="ml-auto mr-auto">-->
    <!--                    <b-card-->
    <!--                        border-variant="primary"-->
    <!--                        align="center"-->
    <!--                    >-->
    <!--                        <b-card-header class="bg-primary text-white p-2">-->
    <!--                            {{eventDetail.event_type}}-->
    <!--                        </b-card-header>-->
    <!--                        <b-card-text class="p-10">-->
    <!--                            <p class="text&#45;&#45;darken-2 mb-2">{{$global.dateFormat(eventDetail.event_from_date)}} - -->
    <!--                                {{$global.dateFormat(eventDetail.event_to_date)}}</p>-->
    <!--                            <b-tag v-for="(d,index) in eventDetail.halls" :key="index">-->
    <!--                                {{d.name}}-->
    <!--                            </b-tag>-->
    <!--                        </b-card-text>-->
    <!--                    </b-card>-->
    <!--                </b-col>-->
    <!--                <b-col v-if="!eventDetail">-->
    <!--                    <h4>No data available</h4>-->
    <!--                </b-col>-->
    <!--            </b-row>-->
    <!--        </b-modal>-->

  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import FollowUp from "./followUp/index";
import SendBrochure from "./sendBrochures/index";
import {bus} from "@/main";
import EventForm from "./form";
import ModuleMixin from "./module.mixin";

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Lead",
    sortable: false,
    value: 'leads',
  },
  {
    text: "Date",
    sortable: false,
    value: 'event_from_date',
  },
  {
    text: "Event",
    sortable: false,
    value: 'title',
  },
  {
    text: "Hall",
    sortable: false,
    value: 'halls',
  },
  {
    text: "Type Of Event",
    sortable: false,
    value: 'event_type',
  },
  {
    text: "Source",
    sortable: false,
    value: 'source_city',
  },
  {
    text: "Created at",
    sortable: false,
    value: 'created_at',
  },
  {
    text: "Status",
    sortable: false,
    value: 'status',
  },
  {
    text: "Tags",
    sortable: false,
    value: 'tags',
  },
  {
    text: "Follow Up",
    sortable: false,
    value: 'follow_up',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  mixins: [ListingMixin, ModuleMixin],
  name: "Events",
  data() {
    return {
      listUrl: '/events',
      columns: COLUMN_DEFINATION(this),
      show_follow_up: false,
    };
  },
  components: {
    KTCard,
    FollowUp,
    SendBrochure,
    EventForm,
  },
  mounted() {
    bus.$on("refresh-page", () => {
      this.loadList(this.listQueryParams);
    });

    this.getHalls();
    this.getTags();
    this.getSources();
    this.getEventType();
    this.getSubUser();
    this.getPendingFollowUpCount();

  },
};
</script>


