<template>
    <div>
        <div class="table-responsive">
            <b-col lg="12" sm="12" class="text-right mt-2 mb-2">
                <form @submit.prevent="handleSubmitOperation">
                    <b-row>

                        <b-col sm="9" lg="9">
                            <b-form-group
                                class="text-left"
                                label="Select Broucher *"
                                label-for="brochure_id"
                                :invalid-feedback="formErrors.first('brochure_id')"
                            >
                                <v-select
                                    multiple
                                    :reduce="item => item.id"
                                    v-model="sendBrochuresFields.brochure_id"
                                    :options="dropdowns.brochures"
                                    :state="((formErrors.has('brochure_id') ? false : null))"
                                >
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3" lg="3" class="mt-8">
                            <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                            >
                                <i v-show="global.pendingRequests > 0"
                                   class="fa fa-spin fa-spinner"></i>
                                <i class="fa fa-save fa-sm"></i>
                                Send Brochure
                            </b-button>
                        </b-col>
                    </b-row>
                </form>
            </b-col>

            <table class="table table-hover table-bordered" v-if="sendBrochuresList.length > 0">
                <thead>
                    <th>SI No.</th>
                    <th>Date/Time</th>
                    <th>Brochure Name</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    <tr v-for="(data,index) in sendBrochuresList" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ $global.dateConvert(data.updated_at) }}</td>
                        <td>
                        <span v-if="data.brochure">
                            {{ data.brochure.title }}
                        </span>
                        </td>
                        <td>
                            <a target="_blank"
                               v-if="data.brochure.media && data.brochure.media.download_url"
                               :href="data.brochure.media.download_url">
                                <i class="fa fa-download"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import vSelect from "vue-select";

const BROCHURE_UP_STATE = {
    brochure_id: null,
    event_id: null,
};


export default {
    props: ["event"],
    data() {
        return {
            sendBrochuresFields: { ...BROCHURE_UP_STATE },
            formErrors: new Error({}),
            sendBrochuresList: [],
            dropdowns: {
                brochures: [],
            }
        };
    },
    mounted() {
        this.handleGetList();
        this.getBrochures();
    },
    components: {
        vSelect
    },
    methods: {
        async handleGetList() {
            if ( this.event ) {
                try {
                    const response = await request({
                        url: `/events/send/brochures/detail/by/event/${ this.event }`,
                        method: 'get',
                    });
                    const { data } = response.data;
                    if ( data ) {
                        this.sendBrochuresList = data;
                    }
                } catch (e) {
                }
            }
        },
        async handleSubmitOperation() {
            this.formErrors = new Error({});

            this.sendBrochuresFields.event_id = this.event;

            try {
                const response = await request({
                    url: this.sendBrochuresFields.id ? 'events/send/brochures/update' : 'events/send/brochures/create',
                    method: 'post',
                    data: {
                        ...this.sendBrochuresFields
                    },
                });
                if ( this.sendBrochuresFields.id ) {
                    this.$global.itemUpdated();
                } else {
                    this.$global.itemAdded();
                }
                this.sendBrochuresFields = { ...this.BROCHURE_UP_STATE };
                this.handleGetList();
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
                this.$global.handleServerError(error);
            }
        },
        async getBrochures() {
            try {
                const response = await request({
                    url: '/dropdowns/brochures',
                    method: "post"
                });

                const { data } = response.data;
                this.dropdowns.brochures = data.map((item) => {
                    return {
                        id: item.id,
                        label: item.title
                    };
                });
            } catch (e) {

            }
        },
    },
    computed: {
        ...mapGetters({ getActiveEvent: 'getActiveEvent' }),
        ...mapState([
            'global',
        ]),
    },
};
</script>
