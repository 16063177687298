<template>
  <div>
    <div class="table-responsive" v-if="!followUpStore">
      <b-col lg="12" sm="12" class="text-right mt-2 mb-2">
        <b-button size="sm" variant="success" @click="followUpStore = !followUpStore;">Add New Follow Up
        </b-button>
      </b-col>

      <table class="table table-hover table-bordered" v-if="followUpList.length > 0">
        <thead>
          <th>SI No.</th>
          <th>Date/Time</th>
          <th>Comments</th>
          <th>Action</th>
        </thead>
        <tbody>
          <tr v-for="(data,index) in followUpList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <p>
                {{ $global.dateFormat(data.date) }}
                {{ $global.timeFormat(data.time) }}
              </p>
            </td>
            <td>{{ data.comments }}</td>
            <td>
              <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                 v-show="$global.hasPermission('followupsupdate')"
                 @click="handleEditOperation(data.id)">
                            <span
                              class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/Communication/Write.svg"
                                                class="action-edit-button"
                                              ></inline-svg>
                              <!--end::Svg Icon-->
                                            </span>

              </a>
              <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                 v-show="$global.hasPermission('followupsdestroy')"
                 @click="handleDeleteOperation(data.id)">
                             <span
                               class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                               <!--end::Svg Icon-->
                                            </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <form @submit.prevent="handleSubmitOperation" enctype="multipart/form-data">
        <!--                <b-col lg="12" sm="12" class="text-right">-->
        <!--                    <b-button size="sm" variant="danger" @click="followUpStore = !followUpStore">Cancel-->
        <!--                    </b-button>-->
        <!--                </b-col>-->
        <b-row sm="12" lg="12">
          <b-col sm="6">
            <b-form-group
              label="Date *"
              label-for="date"
              :invalid-feedback="formErrors.first('date')"
            >
              <b-form-datepicker
                :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                today-button
                reset-button
                close-button
                id="date"
                v-model="followUpFields.date"
                :state="((formErrors.has('date') ? false : null))"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Time *"
              label-for="time"
              :invalid-feedback="formErrors.first('time')"
            >
              <treeselect
                :options="_.map(dropdowns.fromTime,(i) => {
                                    return {
                                        id:i,
                                        label:i
                                    }
                                })"
                v-model="followUpFields.time"
              />
              <span class="text-danger"
                    v-if="formErrors.has('time')">
                                {{ formErrors.first("time") }}
                            </span>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Comments *"
              label-for="comments"
              :invalid-feedback="formErrors.first('comments')"
            >
              <b-form-textarea
                id="comments"
                v-model="followUpFields.comments"
                type="text"
                :state="((formErrors.has('comments') ? false : null))"
              ></b-form-textarea>
            </b-form-group>
          </b-col><!--/b-col-->
        </b-row>
        <b-row class="operation-footer">
          <b-col sm="12">
            <b-button
              size="sm"
              type="submit"
              variant="primary"
              :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button
              class="ml-2"
              size="sm"
              variant="danger"
              :disabled="global.pendingRequests > 0"
              @click="closeOperation"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-arrow-left"></i>
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { bus } from "@/main";
import ModuleMixin from "../module.mixin";

const FOLLOW_UP_STATE = {
  date: null,
  time: null,
  comments: null,
};


export default {
  props: ["event"],
  mixins: [ModuleMixin],
  data() {
    return {
      followUpStore: false,
      followUpFields: { ...FOLLOW_UP_STATE },
      formErrors: new Error({}),
      followUpList: [],
      dropdowns: {
        fromTime: [
          '00:00',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
        ],
      }
    };
  },
  mounted() {
    this.handleGetList();
  },
  methods: {
    closeOperation() {
      this.followUpStore = !this.followUpStore;
      this.followUpFields = { ...this.FOLLOW_UP_STATE };
      this.handleGetList();
    },
    async handleGetList() {
      try {
        const response = await request({
          url: `/events/follow-up/detail/by/event/${this.event}`,
          method: 'get',
        });
        const { data } = response.data;
        if ( data ) {
          this.followUpList = data;
        }

      } catch (e) {
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          url: `/events/follow-up/detail/${id}`,
          method: 'get',
        });
        const { data } = response.data;
        if ( data ) {
          this.followUpFields = {
            ...data,
          };
        }
        this.followUpStore = true;
      } catch (e) {
      }
    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/events/follow-up/delete',
            data: {
              id: id,
            },
          });
          this.$global.itemDeleted();
          this.handleGetList();
          this.$emit('update');
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: this.followUpFields.id ? 'events/follow-up/update' : 'events/follow-up/create',
          method: 'post',
          data: { ...this.followUpFields, event_id: this.event },
        });
        if ( this.followUpFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.followUpFields = { ...this.FOLLOW_UP_STATE };
        this.handleGetList();
        this.followUpStore = !this.followUpStore;
        bus.$emit("refresh-page");
        this.$emit('update');
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
